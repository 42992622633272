/* eslint-disable no-useless-escape */
import AppDispatcher from '../dispatch/AppDispatcher';
import {ReduceStore} from 'flux/utils';
import AnalyticsStore from '../analytics/AnalyticsStore';
import SenderStore from '../sender/SenderStore';
import VideoPlayerStore from '../video/VideoPlayerStore';

class Logger extends ReduceStore {
    constructor() {
        super(AppDispatcher);
    }

    getInitialState() {
        return {};
    }

    reduce(state, action) {
        this.getDispatcher().waitFor([
            AnalyticsStore.getDispatchToken(),
            SenderStore.getDispatchToken(),
            VideoPlayerStore.getDispatchToken()
        ]);

        if (process.env.NODE_ENV === "development") {
            console.log(`Finished dispatch for action type: ${action.type}`);
            console.log(`App state for sender store: ${JSON.stringify(SenderStore.getState(), null, 1)}`)
            console.log(`App state for video store: ${JSON.stringify(VideoPlayerStore.getState(), null, 1)}`)
            console.log(`App state for analytics store: ${JSON.stringify(AnalyticsStore.getState(), null, 1)}`)
        }

        return state;
    }
}

export default new Logger();
