/*
Copyright 2020 Google LLC. All Rights Reserved.
Licensed under the Apache License, Version 2.0 (the "License");
you may not use this file except in compliance with the License.
You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
Unless required by applicable law or agreed to in writing, software
distributed under the License is distributed on an "AS IS" BASIS,
WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
See the License for the specific language governing permissions and
limitations under the License.
*/

/*
 * This software is modified from the original source found here:
 * https://github.com/googleads/googleads-ima-cast/blob/master/client_receiver/player.js
 */

import { AD_REQUEST_BASE, adParams, custAdParams } from "./AdConstants";
import AppDispatcher from '../dispatch/AppDispatcher';
import ActionTypes from '../ActionTypes';
import VideoPlayerListener from "../video/VideoPlayerListener";
import VideoPlayerState from "../video/VideoState";

const NAMESPACE = "urn:x-cast:com.google.ads.ima.cast";
const castSDK = cast.framework; // eslint-disable-line no-undef

/**
 * Creates new player for video and ad playback.
 */
class AdPlayer extends VideoPlayerListener {
  constructor() {
    super();

    this.mediaElement = document.getElementById("player").getMediaElement();
    const options = new castSDK.CastReceiverOptions();

    // Map of namespace names to their types.
    options.customNamespaces = {};
    options.customNamespaces[NAMESPACE] = castSDK.system.MessageType.STRING;
    if (process.env.NODE_ENV === "development") {
      options.maxInactivity = 3600; //Development only
    }
    this.context.start(options);

    this.setupCallbacks();

    this.adErrorCounter = 0;

    this.isNewMediaRequest = false;
  }

  /**
   * Attaches necessary callbacks.
   * @private
   */
  setupCallbacks() {
    if (process.env.NODE_ENV === "development") {
      console.warn("setting up callbacks");
    }
    let self = this;

    // Receives messages from sender app. The message is a comma separated string
    // where the first substring indicates the function to be called and the
    // following substrings are the parameters to be passed to the function.
    this.context.addCustomMessageListener(NAMESPACE, (event) => {
      console.log(event.data);
      let message = event.data.split(",");
      let method = message[0];
      switch (method) {
        case "requestAd":
          let adTag = message[1];
          let currentTime = parseFloat(message[2]);
          self.requestAd(adTag, currentTime);
          break;
        case "seek":
          let time = parseFloat(message[1]);
          self.seek(time);
          break;
        default:
          self.broadcast("Message not recognized");
          break;
      }
    });

    // Initializes IMA SDK when Media Manager is loaded.
    this.playerManager.setMessageInterceptor(
      castSDK.messages.MessageType.LOAD,
      (request) => {
        if (!this.request) {
          self.initIMA();
        }

        // If the loadRequestData is incomplete return an error message
        if (!request || !request.media) {
          const error = new cast.framework.messages.ErrorData( // eslint-disable-line
            cast.framework.messages.ErrorType.LOAD_FAILED // eslint-disable-line
          ); 
          error.reason = cast.framework.messages.ErrorReason.INVALID_REQUEST; // eslint-disable-line
          return error;
        }

        // check all content source fields for asset URL or ID
        let source =
          request.media.contentUrl ||
          request.media.entity ||
          request.media.contentId;

        // If there is no source or a malformed ID then return an error.
        if (!source || source === "") {
          let error = new cast.framework.messages.ErrorData( // eslint-disable-line
            cast.framework.messages.ErrorType.LOAD_FAILED  // eslint-disable-line
          );
          error.reason = cast.framework.messages.ErrorReason.INVALID_REQUEST; // eslint-disable-line
          return error;
        }

        const isSameRequest = this.request?.requestId === request.requestId;
        // Reset ad error counter each time a new video is selected
        if (!isSameRequest) {
          this.isNewMediaRequest = true;
          this.adErrorCounter = 0;
          if (process.env.NODE_ENV === "development") {
            console.warn("resetting ad error counter");
          }
        }

        this.request = request;

        // Update the content type appropriately
        if (
          this.request.media.contentUrl?.includes(".m3u8") ||
          this.request.media.contentId?.includes(".m3u8")
        ) {
          this.request.media.contentType = "application/x-mpegURL";
        } else {
          this.request.media.contentType = "application/dash+xml";
        }

        // TODO: figure out if this does anything
        this.request.withCredentials = true;

        const customPlaybackConfig = {
          protectionSystem: cast.framework.ContentProtection.NONE, // eslint-disable-line
          autoResumeDuration: 20,
          autoResumeNumberOfSegments: 4
        };
        this.playerManager.setPlaybackConfig(customPlaybackConfig);

        // Prevents ad requests from firing in a loop
        if (this.adErrorCounter === 0 && !isSameRequest) {
          if (process.env.NODE_ENV === "development") {
            console.warn("building request...");
          }
          const adTag = this.buildAdRequest(this.request.media);
          this.requestAd(adTag, this.currentContentTime)
        }

        return this.request;
      }
    );
  }

  /**
   * Sends messages to all connected sender apps.
   * @param {string} message Message to be sent to senders.
   * @private
   */
  broadcast(message) {
    this.context.sendCustomMessage(NAMESPACE, undefined, message);
  }

  /**
   * Creates new AdsLoader and adds listeners.
   * @private
   */
  initIMA() {
    if (process.env.NODE_ENV === 'development') {
      console.warn("initializing IMA...");
    }
    this.currentContentTime = -1;
    // eslint-disable-next-line no-undef
    let adDisplayContainer = new google.ima.AdDisplayContainer(
      document.getElementById("adContainer")
    );
    adDisplayContainer.initialize();
    // eslint-disable-next-line no-undef
    this.adsLoader = new google.ima.AdsLoader(adDisplayContainer);
    this.adsLoader.getSettings().setPlayerType("cast/client-side");
    this.adsLoader.addEventListener(
      // eslint-disable-next-line no-undef
      google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED,
      this.onAdsManagerLoaded.bind(this),
      false
    );
    this.adsLoader.addEventListener(
      // eslint-disable-next-line no-undef
      google.ima.AdErrorEvent.Type.AD_ERROR,
      this.onAdError.bind(this),
      false
    );
    this.adsLoader.addEventListener(
      // eslint-disable-next-line no-undef
      google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
      this.onAllAdsCompleted.bind(this),
      false
    );
  }

  /**
   * Sends AdsManager playAdsAfterTime if starting in the middle of content and
   * starts AdsManager.
   * @param {ima.AdsManagerLoadedEvent} adsManagerLoadedEvent The loaded event.
   * @private
   */
  onAdsManagerLoaded(adsManagerLoadedEvent) {
    let adsRenderingSettings = new google.ima.AdsRenderingSettings(); // eslint-disable-line no-undef
    adsRenderingSettings.playAdsAfterTime = this.currentContentTime;

    // Get the ads manager.
    this.adsManager = adsManagerLoadedEvent.getAdsManager(
      this.mediaElement,
      adsRenderingSettings
    );

    this.adsManager.addEventListener(
      // eslint-disable-next-line no-undef
      google.ima.AdEvent.Type.CLICK,
      () => AppDispatcher.dispatch({
        type: ActionTypes.videoAdClicked
      }),
      false
    );
    this.adsManager.addEventListener(
      // eslint-disable-next-line no-undef
      google.ima.AdEvent.Type.STARTED,
      () => AppDispatcher.dispatch({
        type: ActionTypes.videoAdStarted
      }),
      false
    );
    this.adsManager.addEventListener(
      // eslint-disable-next-line no-undef
      google.ima.AdEvent.Type.MIDPOINT,
      () => AppDispatcher.dispatch({
        type: ActionTypes.videoAdPlaying
      }),
      false
    );
    // Currently not supported by the SDK
    // but may be in the future
    this.adsManager.addEventListener(
      // eslint-disable-next-line no-undef
      google.ima.AdEvent.Type.SKIPPED,
      () => AppDispatcher.dispatch({
        type: ActionTypes.videoAdSkipped
      }),
      false
    );
    this.adsManager.addEventListener(
      // eslint-disable-next-line no-undef
      google.ima.AdEvent.Type.COMPLETE,
      () => AppDispatcher.dispatch({
        type: ActionTypes.videoAdCompleted
      }),
      false
    );
    // Add listeners to the required events.
    this.adsManager.addEventListener(
      google.ima.AdErrorEvent.Type.AD_ERROR, // eslint-disable-line no-undef
      this.onAdError.bind(this)
    );
    this.adsManager.addEventListener(
      google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED, // eslint-disable-line no-undef
      this.onContentPauseRequested.bind(this)
    );
    this.adsManager.addEventListener(
      google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, // eslint-disable-line no-undef
      this.onContentResumeRequested.bind(this)
    );

    try {
      this.adsManager.init(
        google.ima.AdsRenderingSettings.AUTO_SCALE, // eslint-disable-line no-undef
        google.ima.AdsRenderingSettings.AUTO_SCALE, // eslint-disable-line no-undef
        google.ima.ViewMode.FULLSCREEN // eslint-disable-line no-undef
      );
      this.adsManager.start();
    } catch (adError) {
      // An error may be thrown if there was a problem with the VAST response.
      this.broadcast("Ads Manager Error: " + adError.getMessage());
      if (process.env.NODE_ENV === 'development') {
        console.error(`Ads Manager Error: ${adError.getMessage()}`)
      }
    }
  }

  /**
   * Handles errors from AdsLoader and AdsManager.
   * @param {ima.AdErrorEvent} adErrorEvent error
   * @private
   */
  onAdError(adErrorEvent) {
    const adErrorMsg = adErrorEvent.getError().getMessage() ?? adErrorEvent.getError().toString();
    this.broadcast("Ad Error: " + adErrorMsg);

    this.adErrorCounter = this.adErrorCounter + 1;
    if (process.env.NODE_ENV === 'development') {
      console.error(`Ad Error: ${adErrorMsg}`);
      console.warn("Ad error counter:", this.adErrorCounter)
    }

    // Handle the error logging.
    if (this.adsManager) {
      this.adsManager.destroy();
    }

    //If an error with the ads occurs, we need to resume the content
    if (this.playerManager.getPlayerState() !== VideoPlayerState.IDLE){
      this.playerManager.play(this.request);
      this.playerManager.seek(this.currentContentTime);
    }
  }

  /**
   * When content is paused by AdsManager to start playing an ad.
   * @private
   */
  onContentPauseRequested() {
    this.playerManager.stop();
    this.currentContentTime = this.mediaElement.currentTime;
    this.broadcast("onContentPauseRequested," + this.currentContentTime);
  }

  /**
   * When an ad finishes playing and AdsManager resumes content.
   * @private
   */
  onContentResumeRequested() {
    this.broadcast("onContentResumeRequested");

    this.playerManager.load(this.request);
    this.seek(this.currentContentTime);
  }

  /**
   * Destroys AdsManager when all requested ads have finished playing.
   * @private
   */
  onAllAdsCompleted() {
    if (this.adsManager) {
      this.adsManager.destroy();
      if (process.env.NODE_ENV === 'development') {
        console.warn("ads complete");
      }
    }
  }

  /**
   * Sets time video should seek to when content resumes and requests ad tag.
   * @param {string} adTag ad tag to be requested.
   * @param {!float} currentTime time of content video we should resume from.
   * @private
   */
   requestAd(adTag, currentTime) {
    if (currentTime !== 0) {
      this.currentContentTime = currentTime;
    }
    let adsRequest = new google.ima.AdsRequest(); // eslint-disable-line no-undef
    adsRequest.adTagUrl = adTag;
    adsRequest.linearAdSlotWidth = this.mediaElement.width;
    adsRequest.linearAdSlotHeight = this.mediaElement.height;
    adsRequest.nonLinearAdSlotWidth = this.mediaElement.width;
    adsRequest.nonLinearAdSlotHeight = this.mediaElement.height / 3;
    adsRequest.vastLoadTimeout = 7000;
    adsRequest.setAdWillAutoPlay(true);

    // Fetch the adTag and verify if its a skippable ad, if it is, skip loading it into the adsLoader.
    // Skippable ads not supported by current SDK.
    fetch(adTag)
    .then(response => response.text())
    .then(text => {
      if(!text.includes("SkippableAdType")){
        if (process.env.NODE_ENV === 'development') {      
          console.warn("requesting ad...", adTag);
        }
        this.adsLoader.requestAds(adsRequest);
      }else{
        console.warn("avoid loading skippable ad...", adTag);
      }
    });
  }

  /**
   * Seeks content video.
   * @param {!float} time time to seek to.
   * @private
   */
  seek(time) {
    this.currentContentTime = time;
    this.playerManager.seek(time);
    if (
      this.playerManager.getPlayerState() ===
      castSDK.messages.PlayerState.PAUSED
    ) {
      this.playerManager.play();
    }
  }

  /**
   * Builds custom ad parameters based on selected media.
   * @param {object} media .
   * @private
   */
   buildCustParams(media) {
    const { customData } = media;

    let custParams = '';

    if (customData["cust_params"]) {
      // iOS sends all custom params in one string
      custParams = encodeURIComponent(customData["cust_params"]);
    } else {
      custAdParams.forEach((item) => {
        const { paramKey } = item;

        const hasValue = customData?.[paramKey] ?? false;
        const isFirstWithValue = custParams === '';
        const encodedAmpersand = encodeURIComponent('&');
        const encodedEquals = encodeURIComponent('=');
        let encodedValue = encodeURIComponent(customData[paramKey]);
        
        if (hasValue && isFirstWithValue) {
          // first custom param should not have '&' syntax
          custParams += `${paramKey}${encodedEquals}${encodedValue}`;
        }
        else if (hasValue && !isFirstWithValue) {
          custParams += `${encodedAmpersand}${paramKey}${encodedEquals}${encodedValue}`;
        }
      })
    }

    custParams = `&cust_params=${custParams}`;

    return custParams;
  }

  /**
   * Builds ad request url and parameters.
   * @param {object} media .
   * @private
   */
  buildAdRequest(media) {
    const { customData } = media;

    let requestUrl = `${AD_REQUEST_BASE}`;

    adParams.forEach((item) => {
      const { paramKey, isEncoded } = item;
      const hasValue = customData?.[paramKey] ?? false;

      if (isEncoded && hasValue) {
        requestUrl += `&${paramKey}=${encodeURIComponent(customData[paramKey])}`;
      }
      if (!isEncoded && hasValue) {
        requestUrl += `&${paramKey}=${customData[paramKey]}`;
      }
    });

    const encodedCustParams = this.buildCustParams(media);
    requestUrl += encodedCustParams;

    return requestUrl;
  }
}
export default AdPlayer;