const VideoPlayerState = {
    IDLE: "idle",
    PLAYING: "playing",
    PAUSED: "paused",
    LOADING: "loading",
    SEEKING: "seeking",
    OTHER: "other"
}

export default VideoPlayerState;
