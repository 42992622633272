const SenderType = {
    TOUCH_CONTROLS: "touch_controls",
    VOICE: "voice",
    CHROME_SENDER: "chrome",
    IOS_SENDER: "iOS",
    ANDROID_SENDER: "android",
    OTHER_SENDER: "other",
}

export default SenderType;
