import CastListener from '../CastListener';
import {CastEventType} from '../CastEventTypes';
import AppDispatcher from '../dispatch/AppDispatcher';
import ActionTypes from '../ActionTypes';
import { AnalyticsValues } from '../analytics/AnalyticsConstants';
import VideoPlayerStore from './VideoPlayerStore';
import VideoPlayerState from './VideoState';

class VideoPlayerListener extends CastListener {
    static PLAYER_TIMER_MS = 15000;

    constructor() {
        super();
        this.eventTypes = [
            CastEventType.LOAD_START,
            CastEventType.PAUSE,
            CastEventType.PLAY,
            CastEventType.BITRATE_CHANGED,
            CastEventType.REQUEST_SEEK, //TODO: Figure out if we need to send event here
            CastEventType.PROGRESS,
            CastEventType.MEDIA_INFORMATION_CHANGED, // TODO: Figure out how to send track change updates here
            CastEventType.MEDIA_STATUS,
            CastEventType.LIVE_ENDED,
            CastEventType.ENDED,
        ];

        this.playerTimer = null;
    }

    async getMedia() {
       return await this.playerManager.getMediaInformation();
    }

    handleEvent(event) {
        if (process.env.NODE_ENV === "development") {
            console.log(`Video player event: ${JSON.stringify(event, null, 1)}`)
        }
        this.getMedia().then((media) =>  {
            switch(event.type) {
                case CastEventType.BITRATE_CHANGED.event:
                    let newBitrate = "";
                    if (event.totalBitrate !== null) {
                        newBitrate = event.totalBitrate.toString();
                    }
    
                    AppDispatcher.dispatch({
                        type: ActionTypes.videoBitrateChanged,
                        bitrate: newBitrate,
                    });
                    break;
                case CastEventType.MEDIA_STATUS.event:
                    const mediaStatus = event.mediaStatus ? event.mediaStatus : false;
                    if (mediaStatus && (media !== undefined || media !== null)) {
                        let autoPlay = undefined;
                        const items = mediaStatus?.items;
                        if (items !== undefined && items.length > 0) {
                            const thisItem = items.find(item => item.media.contentUrl === media.contentUrl);
                            if (thisItem !== undefined) {
                                autoPlay = thisItem.autoPlay;
                            } else {
                                // work around for data discrepancy: iOS - contentUrl, Android - contentId 
                                // TODO: review if this has been fixed to clean up extra logic. 
                                const thisItemAndroid = items.find(item => item.media.contentId === media.contentId); 
                                if (thisItemAndroid !== undefined) {
                                    autoPlay = thisItem.autoPlay;
                                }
                            }
                        }
    
                        let textTrack = null;
                        if (mediaStatus.activeTrackIds !== undefined) {
                            const activeTracks = mediaStatus.activeTrackIds
                            const textTrackManager = this.playerManager.getTextTracksManager();
                            for (let i = 0; i < activeTracks.length; i++) {
                                const trackId = activeTracks[i];
                                try {
                                    const track = textTrackManager.getTrackById(trackId);
                                    if (track !== null) {
                                        textTrack = track;
                                        break;
                                    } else {
                                    }
                                } catch {
                                }
                            }
                        }
    
                        let textTracksChanged = false;
                        const currTrack = VideoPlayerStore.getSubtitleTrack() ?? null;
    
                        if (textTrack !== null && currTrack !== null) {
                            textTracksChanged = textTrack.trackId !== currTrack.trackId;
                        } else {
                            textTracksChanged = textTrack !== currTrack;
                        }

                        if (media) {
                            AppDispatcher.dispatch({
                                type: ActionTypes.updateMediaItem,
                                mediaItem: {
                                    url: media.contentId ? media.contentId : media.contentUrl, // work around for data discrepancy: iOS - contentUrl, Android - contentId
                                                                                               // should be fixed in upcoming ticket: iOS-672
                                    streamType: this.getStreamType(media.streamType),
                                    duration: media.duration? media.duration.toFixed(0) : "",
                                    meta: media.customData,
                                    title: media.metadata.title,
                                    subtitle: media.metadata.subtitle,
                                    textTrack: textTrack,
                                    autoPlay: autoPlay,
                                }
                            });
                        }
    
                        if (textTracksChanged) {
                            AppDispatcher.dispatch({
                                type: ActionTypes.subtitlesChanged
                            })
                        }
                    }
                    break;
                case CastEventType.PROGRESS.event:
                    AppDispatcher.dispatch({
                        type: ActionTypes.videoProgressChanged,
                        progress: event.currentMediaTime.toFixed(0),
                    })
                    break;
                case CastEventType.PAUSE.event:
                    AppDispatcher.dispatch({
                        type: ActionTypes.videoContentPaused
                    })
                    break;
                case CastEventType.PLAYER_LOADING.event:
                    AppDispatcher.dispatch({
                        type: ActionTypes.playerLoading
                    })
                    break;
                case CastEventType.LOAD_START.event:
                    AppDispatcher.dispatch({
                        type: ActionTypes.updateMediaItem,
                        mediaItem: {
                            url: media.contentId ? media.contentId : media.contentUrl, // work around for data discrepancy: iOS - contentUrl, Android - contentId
                            streamType: this.getStreamType(media.streamType),
                            duration: media.duration? media.duration.toFixed(0) : "",
                            meta: media.customData,
                            title: media.metadata.title,
                            subtitle: media.metadata.subtitle,
                        }
                    });
    
                    media && this.styleProgressBar(media);
    
                    AppDispatcher.dispatch({
                        type: ActionTypes.videoContentPlay,
                    });
    
                    // Start play timer
                    if (this.playerTimer !== null) {
                        clearInterval(this.playerTimer);
                    }
    
                    this.playerTimer = setInterval( () => {
                        AppDispatcher.dispatch({
                            type: ActionTypes.videoContentPlaying
                        })
                    }, VideoPlayerListener.PLAYER_TIMER_MS);
                    break;
                case CastEventType.PLAY.event:
                    media && this.styleProgressBar(media);
                    // eslint-disable-next-line no-undef
                    if (VideoPlayerStore.getPlayerState() === VideoPlayerState.PAUSED) {
                        AppDispatcher.dispatch({
                            type: ActionTypes.videoContentResumed
                        });
                    } else {
                        AppDispatcher.dispatch({
                            type: ActionTypes.videoContentStarted
                        });
                    }
                    break;
                case CastEventType.LIVE_ENDED.event:
                    AppDispatcher.dispatch({
                        type: ActionTypes.videoContentCompleted
                    })
    
                    // Stop player timer
                    if (this.playerTimer !== null) {
                        clearInterval(this.playerTimer);
                    }
                    break;
                case CastEventType.ENDED.event:
                    AppDispatcher.dispatch({
                        type: ActionTypes.videoContentCompleted
                    })
    
                    // Stop player timer
                    if (this.playerTimer !== null) {
                        clearInterval(this.playerTimer);
                    }
                    break;
                default:
                    return;
            }
        }).catch((err) => {
            console.error("Error getting media information:", err)
        })
    }

    getStreamType(type) {
        // eslint-disable-next-line no-undef
        if (type === cast.framework.messages.StreamType.LIVE) {
            return AnalyticsValues.liveVideoType;
        } else {
            return AnalyticsValues.vodVideoType;
        }
    }

    styleProgressBar(media) {
        const streamType = this.getStreamType(media.streamType);
        let playerElement = document.getElementsByTagName("cast-media-player")[0];

        switch (streamType) {
          case "vod":
            playerElement.style.setProperty("--progress-color", "rgb(0, 120, 208)"); // OLYMPICS BLUE
            break;
          case "live":
            playerElement.style.setProperty("--progress-color", "rgb(240, 40, 45)"); // OLYMPICS RED
            break;
          default:
            playerElement.style.setProperty("--progress-color", "rgb(0, 120, 208)"); // OLYMPICS BLUE
            break;
        }
    }
}

export default VideoPlayerListener;
