import { EventOwner } from './CastEventTypes';

/**
 * Template class for listening to cast events. Override the eventTypes array with the event types
 * the listener needs to listen to.
 * Call start() on the listener object to start listening to cast events.
 */
class CastListener {
    constructor() {
        // eslint-disable-next-line no-undef
        this.context = cast.framework.CastReceiverContext.getInstance();
        this.playerManager = this.context.getPlayerManager();
        // Override event types to listen to them in subclass.
        this.eventTypes = [];
    }

    start() {
        this.eventTypes.forEach((eventType) => {
            if (eventType.owner === EventOwner.PLAYER_MANAGER) {
                this.playerManager.addEventListener(eventType.event, this.handleEvent.bind(this));
            } else if (eventType.owner === EventOwner.CAST_RECEIVER_CONTEXT) {
                this.context.addEventListener(eventType.event, this.handleEvent.bind(this));
            }
        });
    }

    /**
     * Template event handler that should be overidden in subclass.
     * @param  {cast.framework.events.*} event
     */
    handleEvent(event) {}
}

export default CastListener;
