import CastListener from '../CastListener';
import {CastEventType} from '../CastEventTypes';
import AppDispatcher from '../dispatch/AppDispatcher';
import ActionTypes from '../ActionTypes';

class SenderListener extends CastListener {
    constructor() {
        super();
        this.eventTypes = [
            CastEventType.SENDER_CONNECTED,
            CastEventType.SENDER_DISCONNECTED
        ];
    }

    handleEvent(event) {
        if (event.type === CastEventType.SENDER_CONNECTED.event) {
            AppDispatcher.dispatch({
                type: ActionTypes.senderConnected,
                sender: {
                    senderId: event.senderId,
                    userAgent: event.userAgent
                },
            });
        } else if (event.type === CastEventType.SENDER_DISCONNECTED.event) {
            AppDispatcher.dispatch({
                type: ActionTypes.senderDisconnected,
                senderId: event.senderId,
            });
        }
    }
}

export default SenderListener;
