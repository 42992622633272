import AppDispatcher from '../dispatch/AppDispatcher';
import Immutable from 'immutable';
import {ReduceStore} from 'flux/utils';
import ActionTypes from '../ActionTypes';
import VideoPlayerState from './VideoState';
import {AnalyticsKeys, AnalyticsValues} from '../analytics/AnalyticsConstants';

class VideoPlayerStore extends ReduceStore {
    constructor() {
        super(AppDispatcher);
    }

    getInitialState() {
        return Immutable.Map(this.getDefaultPlayerProperties());
    }

    reduce(state, action) {
        switch(action.type) {
            case ActionTypes.videoBitrateChanged:
                return state.set(AnalyticsKeys.bitrate, action.bitrate)
            case ActionTypes.videoProgressChanged:
                return state.merge({
                    [AnalyticsKeys.watchTime]: action.progress.toString(),
                })
            case ActionTypes.updateMediaItem:
                const newProperties = this.updateProperties(action.mediaItem);
                return state.merge(newProperties);
            case ActionTypes.videoContentStarted:
                return state.set("playerState", VideoPlayerState.PLAYING);
            case ActionTypes.videoContentPaused:
                return state.set("playerState", VideoPlayerState.PAUSED);
            case ActionTypes.videoContentResumed:
                return state.set("playerState", VideoPlayerState.PLAYING);
            case ActionTypes.videoContentCompleted:
                return state.set("playerState", VideoPlayerState.IDLE);
            case ActionTypes.videoContentPlay:
                return state.set("playerState", VideoPlayerState.LOADING);
            case ActionTypes.playerLoading:
                // Reset the state here
                // Update the player state to loading
                return this.getInitialState()
                    .set("playerState", VideoPlayerState.LOADING);
            default:
                return state;
        }
    }

    updateProperties(mediaItem) {
        let props = {
            [AnalyticsKeys.videoType]: mediaItem.streamType,
            [AnalyticsKeys.videoUrl]: mediaItem.url,
        }

        if (mediaItem.textTrack !== undefined && mediaItem.textTrack !== null) {
            props[AnalyticsKeys.subtitleSelected] = AnalyticsValues.hasSubtitlesSelected
            props[AnalyticsKeys.subtitleLanguage] = mediaItem.textTrack.language ?? ""
        } else {
            props[AnalyticsKeys.subtitleSelected] = AnalyticsValues.noSubtitlesSelected
            props[AnalyticsKeys.subtitleLanguage] = ""
        }

        props["subtitleTrack"] = mediaItem.textTrack;

        if (mediaItem.videoType === AnalyticsValues.liveVideoType) {
            props[AnalyticsKeys.videoLength] = null;
            props[AnalyticsKeys.watchTime] = null;
        } else {
            props[AnalyticsKeys.videoLength] = mediaItem.duration.toString();
        }

        return props;
    }

    getDefaultPlayerProperties() {
        return {
            [AnalyticsKeys.videoWidth]: window.outerWidth,
            [AnalyticsKeys.videoHeight]: window.outerHeight,
            [AnalyticsKeys.bitrate]: "",
            [AnalyticsKeys.videoLength]: null,
            [AnalyticsKeys.videoType]: AnalyticsValues.vodVideoType,
            [AnalyticsKeys.watchTime]: null,
            playerState: VideoPlayerState.IDLE,
        };
    }

    getSubtitleTrack() {
        return this.getState().get("subtitleTrack");
    }

    getPlayerState() {
        return this.getState().get("playerState")
    }
}

export default new VideoPlayerStore();
