// Needs the first param appended before adding the others due to question mark syntax
export const AD_REQUEST_BASE =
  "https://pubads.g.doubleclick.net/gampad/ads?env=vp";

export const adParams = [
  { paramKey: "ad_rule", isEncoded: false },
  { paramKey: "cmsid", isEncoded: false },
  { paramKey: "description_url", isEncoded: true },
  { paramKey: "gdfp_req", isEncoded: false },
  { paramKey: "idtype", isEncoded: false },
  { paramKey: "is_lat", isEncoded: false },
  { paramKey: "iu", isEncoded: false },
  { paramKey: "language_version", isEncoded: false },
  { paramKey: "lip", isEncoded: false },
  { paramKey: "min_ad_duration", isEncoded: false },
  { paramKey: "max_ad_duration", isEncoded: false },
  { paramKey: "output", isEncoded: false },
  { paramKey: "pod", isEncoded: false },
  { paramKey: "ppos", isEncoded: false },
  { paramKey: "rdid", isEncoded: false },
  { paramKey: "sz", isEncoded: false },
  { paramKey: "tfcd", isEncoded: false },
  { paramKey: "unviewed_position_start", isEncoded: false },
  { paramKey: "url", isEncoded: true },
  { paramKey: "vad_type", isEncoded: false },
  { paramKey: "vid", isEncoded: false },
  { paramKey: "vpa", isEncoded: false },
  { paramKey: "vpmute", isEncoded: false },
];

// All custom params are encoded
export const custAdParams = [
  { paramKey: "athlete" },
  { paramKey: "channel" },
  { paramKey: "commercial" },
  { paramKey: "content_type" },
  { paramKey: "environment" },
  { paramKey: "episode_title" },
  { paramKey: "event_name" },
  { paramKey: "language_version" },
  { paramKey: "platform" },
  { paramKey: "show_name" },
  { paramKey: "sponsor" },
  { paramKey: "sport" },
];