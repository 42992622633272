import './index.css';
import VideoPlayerListener from './video/VideoPlayerListener';
import SenderListener from './sender/SenderListener';
import VideoPlayerStore from './video/VideoPlayerStore';
import SenderStore from './sender/SenderStore';
import AnalyticsStore from './analytics/AnalyticsStore';
// eslint-disable-next-line no-unused-vars
import Logger from './log/Logger';
import AppDispatcher from './dispatch/AppDispatcher';
import ActionTypes from './ActionTypes';
import SegmentStore from './analytics/services/SegmentStore';
// eslint-disable-next-line no-unused-vars
import AdPlayer from "./ads/AdPlayer";


// Register analytics services
AppDispatcher.dispatch({
  type: ActionTypes.registerService,
  serviceType: "Analytics",
  service: SegmentStore
})

// Start listeners
const castListeners = [
  new VideoPlayerListener(),
  new SenderListener(),
]

castListeners.forEach((listener) => {
  listener.start();
})

if (process.env.NODE_ENV === "development") {
  console.log("Started app listeners")

  // Enable Cast Debug Logger
  const castDebugLogger = cast.debug.CastDebugLogger.getInstance(); // eslint-disable-line

  // Enable debug logger and show a 'DEBUG MODE' overlay at top left corner.
  castDebugLogger.setEnabled(true);

  console.log(`App state for video store: ${JSON.stringify(VideoPlayerStore.getState(), null, 1)}`)
  console.log(`App state for sender store: ${JSON.stringify(SenderStore.getState(), null, 1)}`)
  console.log(`App state for analytics store: ${JSON.stringify(AnalyticsStore.getState(), null, 1)}`)
}

let adPlayer = new AdPlayer(); // eslint-disable-line no-unused-vars