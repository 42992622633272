const AnalyticsKeys = {
    // Page related keys (which app page users are on when casting)
    pageType: "page_type", 
    subdomain: "subdomain",

    // User related
    authenticated: "authenticated",
    language: "language",
    userNation: "user_nation",

    // Content related - only need to send the fields below if they are available
    competitionEvent: "competition_event",
    contentId: "content_id",
    contentGeography: "content_geography",
    contentReleaseDate: "content_release_date_local_utc",
    contentSource: "content_source",
    contentTitle: "content_title",
    discipline: "discipline",
    externalContentSource: "external_content_source",
    externalContentSourceId : "external_content_source_id",
    slug: "slug",

     // Video related keys
    episodeUid: "episode_uid", // may be VOD only
    episodeNumber: "episode_number", // (VOD only)
    episodeSynopsis: "episode_synopsis",
    programId: "program_id",
    seasonBucketId: "season_bucket_id", // may be VOD only
    seasonName: "season_name", // may be VOD only
    seriesId: "series_id", // may be VOD only
    seriesTitle: "series_title", // may be VOD only
    subtitleLanguage: "subtitle_language",
    subtitleSelected: "subtitle_selected",
    videoId: "video_id",  // (VOD only)
    videoPlayer: "video_player",
    videoProgress: "video_progress",
    videoType: "video_type",
    videoUrl: "video_url",
    watchTime: "watch_time",
    videoChromecasted: "video_chromecasted",
 
    // Misc
    autoplay: "autoplay",
    bitrate: "bitrate",
    event: "event",
    implementationProvider: "implementation_provider",
    platform: "platform",
    nonIteraction: "non_interaction",
    videoLength: "video_length",
    videoHeight: "video_height",
    videoWidth: "video_Width",
}

const AnalyticsValues = {
    implementationProvider: "Wunderman Thompson Apps",
    interactionValue: false,
    noninteractionValue: true,
    navigatedToScreen: "Navigated To Screen",
    liveVideoType: "live",
    vodVideoType: "vod",
    forwardScrubType: "+10",
    backwardScrubType: "-10",
    videoPlayer: "Cast Player",
    hasSubtitlesSelected: "1",
    noSubtitlesSelected: "0",
    videoChromecasted: true,
}

export { AnalyticsKeys, AnalyticsValues }
