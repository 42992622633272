import AppDispatcher from '../../dispatch/AppDispatcher';
import {ReduceStore} from 'flux/utils';
import SenderStore from '../../sender/SenderStore';
import ActionTypes from '../../ActionTypes';
import SenderType from '../../sender/SenderType';
import Immutable from 'immutable';

class SegmentStore extends ReduceStore {
    constructor() {
        super(AppDispatcher);
        this.setupSegment();
        this.analytics = window.analytics;
    }

    getInitialState() {
        return Immutable.Map({
            initalized: false
        });
    }

    reduce(state, action) {
        this.getDispatcher().waitFor([SenderStore.getDispatchToken()]);

        switch(action.type) {
            case ActionTypes.updateMediaItem:
                if (!state.get('initalized')) {
                    const sender = SenderStore.getSender();
                    let writeKey = "";
                    // Use segment key provided by app
                    if (action.mediaItem.meta.SEGMENT_WRITE_KEY) {
                        writeKey = action.mediaItem.meta.SEGMENT_WRITE_KEY;
                    } 
                    // Fallback to .env segment key based on sender type
                    else if (sender.type === SenderType.IOS_SENDER) {
                        writeKey = process.env.REACT_APP_FALLBACK_IOS_WRITE_KEY;
                    } else {
                        writeKey = process.env.REACT_APP_FALLBACK_ANDROID_WRITE_KEY;
                    }
                    this.analytics.load(writeKey);
                    this.analytics.page();
                    return state.set('initalized', true)
                } else {
                    return state;
                }
            default:
                return state;
        }
    }

    setupSegment() {
        // Note- this was modified from the provided segment snippet found here:
        // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/quickstart/

        // Create a queue, but don't obliterate an existing one!
        let analytics = window.analytics = window.analytics || [];

        // If the real analytics.js is already on the page return.
        if (analytics.initialize) return;

        // If the snippet was invoked already show an error.
        if (analytics.invoked) {
        if (window.console && console.error) {
            console.error('Segment snippet included twice.');
        }
        return;
        }

        // Invoked flag, to make sure the snippet
        // is never invoked twice.
        analytics.invoked = true;

        // A list of the methods in Analytics.js to stub.
        analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware'
        ];

        // Define a factory to create stubs. These are placeholders
        // for methods in Analytics.js so that you never have to wait
        // for it to load to actually record data. The `method` is
        // stored as the first argument, so we can replay the data.
        analytics.factory = function(method){
        return function(){
            let args = Array.prototype.slice.call(arguments);
            args.unshift(method);
            analytics.push(args);
            return analytics;
        };
        };

        // For each of our methods, generate a queueing stub.
        for (let i = 0; i < analytics.methods.length; i++) {
        let key = analytics.methods[i];
        analytics[key] = analytics.factory(key);
        }

        // Define a method to load Analytics.js from our CDN,
        // and that will be sure to only ever load it once.
        analytics.load = function(key, options){
        // Create an async script element based on your key.
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://cdn.segment.com/analytics.js/v1/'
            + key + '/analytics.min.js';

        // Insert our script next to the first script element.
        let first = document.getElementsByTagName('script')[0];
        first.parentNode.insertBefore(script, first);
        analytics._loadOptions = options;
        };

        // Add a version to keep track of what's in the wild.
        analytics.SNIPPET_VERSION = '4.1.0';
    }

    // Analytics Service method to track
    trackEvent(event, payload) {
        window.analytics.track(event, payload);
        if (process.env.NODE_ENV === "development") {
            console.log(`Segment- video event ${event}\n${JSON.stringify(payload, null, 1)}`);
        }
    }
}

export default new SegmentStore();
