/* eslint-disable no-useless-escape */
import AppDispatcher from '../dispatch/AppDispatcher';
import Immutable from 'immutable';
import {ReduceStore} from 'flux/utils';
import ActionTypes from '../ActionTypes';
import SenderType from './SenderType';

class SenderStore extends ReduceStore {
    constructor() {
        super(AppDispatcher);

        this.SenderIdRegex = {
            [SenderType.TOUCH_CONTROLS]: "^__touch_controls__$",
            [SenderType.VOICE]: "^__inject__$",
            [SenderType.CHROME_SENDER]: "^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-"
              + "[a-z0-9]{12}\.[0-9]+\:[0-9]+$",
            [SenderType.IOS_SENDER]: "^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-"
              + "[a-z0-9]{12}\.[0-9]+\:[A-Z0-9]{8}-[A-Z0-9]{4}-[A-Z0-9]{4}-"
              + "[A-Z0-9]{4}-[A-Z0-9]{12}$",
            [SenderType.ANDROID_SENDER]: "^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-"
              + "[a-z0-9]{4}-[a-z0-9]{12}\.[0-9]+\:"
              + "(com.google.sample.cast.refplayer)-[0-9]+$"
          }
    }

    getSender() {
        const senders = this.getState().toJS();
        const senderIds = Object.keys(senders);
        if (senderIds.length > 0) {
            return senders[senderIds[0]];
        } else {
            return null;
        }
    }

    getInitialState() {
        return Immutable.Map(this.getConnectedSenders());
    }

    reduce(state, action) {
        switch(action.type) {
            case ActionTypes.senderConnected:
                return state.set(action.sender.senderId, {
                    type: this.getSenderType(action.sender.senderId),
                    id: action.sender.senderId,
                    userAgent: action.sender.userAgent,
                });
            case ActionTypes.senderDisconnected:
                return state.delete(action.senderId);
            default:
                return state;
        }
    }

    getConnectedSenders() {
        // eslint-disable-next-line no-undef
        const context = cast.framework.CastReceiverContext.getInstance();
        if (context.getSenders().length > 0) {
            return context.getSenders().reduce((result, sender) => {
                result[sender.senderId] = {
                    type: this.getSenderType(sender.senderId),
                    id: sender.senderId,
                    userAgent: sender.userAgent,
                };
                return result;
            });
        } else {
            return {};
        }
    }

    getSenderType(id, userAgent = "") {
        let senderType = SenderType.OTHER_SENDER;
        Object.entries(this.SenderIdRegex).forEach(([currType, regex]) => {
          if (RegExp(regex).test(id)) {
            senderType = currType;
          }
        });

        if (senderType === SenderType.OTHER_SENDER) {
            if (userAgent.includes("iOS")) {
                senderType = senderType.IOS_SENDER;
            }
        }

        return senderType;
    }
}

export default new SenderStore();
