import AppDispatcher from '../dispatch/AppDispatcher';
import Immutable from 'immutable';
import {ReduceStore} from 'flux/utils';
import {AnalyticsKeys, AnalyticsValues} from './AnalyticsConstants';
import ActionTypes from '../ActionTypes';
import VideoPlayerStore from '../video/VideoPlayerStore';

class AnalyticsStore extends ReduceStore {

    // Stores registered analytics services
    #services = [];

    constructor() {
        super(AppDispatcher);
    }

    getInitialState() {
        return Immutable.Map(this.defaultAnalyticsPayload());
    }

    reduce(state, action) {
        // Make sure the video player store has updated first
        AppDispatcher.waitFor([VideoPlayerStore.getDispatchToken()]);

        // First check if this is an analytics tracking action
        if(ActionTypes.trackingActions.includes(action.type)) {
            // Update with new state
            const newState = this.reduceVideoTrackingAction(state, action.type);
            // Dispatch event to services
            this.#services.forEach(service => {
                service.trackEvent(newState.get(AnalyticsKeys.event), this.propsForEvent(action.type, newState.toJS()));
            })

            return newState;
        } else {
            // Switch on action types and update event properties
            switch(action.type) {
                case ActionTypes.registerService:
                    if (action.serviceType === "Analytics") {
                        this.#services.push(action.service);
                    }
                    return state;
                case ActionTypes.updateMediaItem:
                    const mediaItem = action.mediaItem;
                    let props = {
                        // User related
                        [AnalyticsKeys.authenticated]: mediaItem.meta?.[AnalyticsKeys.authenticated] ?? false,
                        [AnalyticsKeys.language]: mediaItem.meta?.[AnalyticsKeys.language] ?? "en",
                        [AnalyticsKeys.userNation]: mediaItem.meta?.[AnalyticsKeys.userNation] ?? "US",
                        [AnalyticsKeys.platform]: mediaItem.meta?.[AnalyticsKeys.platform] ? mediaItem.meta?.[AnalyticsKeys.platform] : "ios",
                        // Content related
                        [AnalyticsKeys.competitionEvent]: mediaItem.meta?.[AnalyticsKeys.competitionEvent] ?? "",
                        [AnalyticsKeys.contentId]: mediaItem.meta?.[AnalyticsKeys.contentId] ?? "",
                        [AnalyticsKeys.contentGeography]: mediaItem.meta?.[AnalyticsKeys.contentGeography] ?? "",
                        [AnalyticsKeys.contentReleaseDate]: mediaItem.meta?.[AnalyticsKeys.contentReleaseDate] ?? "",
                        [AnalyticsKeys.contentSource]: mediaItem.meta?.[AnalyticsKeys.contentSource] ?? "",
                        [AnalyticsKeys.contentTitle]: mediaItem.meta?.[AnalyticsKeys.contentTitle] ?? "",
                        [AnalyticsKeys.discipline]: mediaItem.meta?.[AnalyticsKeys.discipline] ?? "",
                        [AnalyticsKeys.externalContentSource]: mediaItem.meta?.[AnalyticsKeys.externalContentSource] ?? "",
                        [AnalyticsKeys.externalContentSourceId]: mediaItem.meta?.[AnalyticsKeys.externalContentSourceId] ?? "",
                        [AnalyticsKeys.slug]: mediaItem.meta?.[AnalyticsKeys.slug] ?? "",
                        // Video Related
                        [AnalyticsKeys.episodeUid]: mediaItem.meta?.[AnalyticsKeys.episodeUid] ?? "",
                        [AnalyticsKeys.episodeNumber]: mediaItem.meta?.[AnalyticsKeys.episodeNumber] ?? -1,
                        [AnalyticsKeys.episodeSynopsis]: mediaItem.meta?.[AnalyticsKeys.episodeSynopsis] ?? "",
                        [AnalyticsKeys.programId]: mediaItem.meta?.[AnalyticsKeys.programId] ?? -1,
                        [AnalyticsKeys.seasonBucketId]: mediaItem.meta?.[AnalyticsKeys.seasonBucketId] ?? -1,
                        [AnalyticsKeys.seasonName]: mediaItem.meta?.[AnalyticsKeys.seasonName] ?? "",
                        [AnalyticsKeys.seriesId]: mediaItem.meta?.[AnalyticsKeys.seriesId] ?? "",
                        [AnalyticsKeys.seriesTitle]: mediaItem.meta?.[AnalyticsKeys.seriesTitle] ?? "",
                        [AnalyticsKeys.videoId]: mediaItem.meta?.[AnalyticsKeys.videoId] ?? "",
                        [AnalyticsKeys.videoPlayer]: AnalyticsValues.videoPlayer,
                        [AnalyticsKeys.videoProgress]: this.getVideoProgress() ? parseInt(this.getVideoProgress) : 0,
                        [AnalyticsKeys.videoType]: mediaItem.streamType ? mediaItem.streamType : "",
                        [AnalyticsKeys.videoUrl]: mediaItem.meta?.[AnalyticsKeys.videoUrl] ?? "",
                        [AnalyticsKeys.videoChromecasted]: AnalyticsValues.videoChromecasted,
                    }

                    if (mediaItem.hasOwnProperty("subtitleTrack") && mediaItem.subtitleTrack !== null) {
                        props[AnalyticsKeys.subtitleLanguage] = mediaItem.meta?.[AnalyticsKeys.subtitleLanguage] ?? "";
                        props[AnalyticsKeys.subtitleSelected] = AnalyticsValues.hasSubtitlesSelected;
                    }

                    if (mediaItem.videoType === AnalyticsValues.vodVideoType) {
                        props[AnalyticsKeys.videoLength]= mediaItem.duration.toString();
                    }

                    if (mediaItem.autoPlay !== undefined) {
                        props[AnalyticsKeys.autoplay] = mediaItem.autoPlay.toString();
                    } else {
                        props[AnalyticsKeys.autoplay] = "true"
                    }
                    
                    return state.merge(props);
                case ActionTypes.playerLoading:
                    // Reset properties
                    return this.getInitialState();
                default:
                    return state;
            }
        }
    }

    reduceVideoTrackingAction(state, actionName) {
        const videoState = VideoPlayerStore.getState();
        const newProps = {
            [AnalyticsKeys.event]: actionName,
            [AnalyticsKeys.bitrate]: videoState.get(AnalyticsKeys.bitrate),
            [AnalyticsKeys.nonIteraction]: this.interactionForAction(actionName),
            [AnalyticsKeys.subtitleLanguage]: videoState.get(AnalyticsKeys.subtitleLanguage),
            [AnalyticsKeys.subtitleSelected]: videoState.get(AnalyticsKeys.subtitleSelected),
            [AnalyticsKeys.videoLength]: videoState.get(AnalyticsKeys.videoLength),
            [AnalyticsKeys.videoHeight]: videoState.get(AnalyticsKeys.videoHeight),
            [AnalyticsKeys.videoWidth]: videoState.get(AnalyticsKeys.videoWidth),
            [AnalyticsKeys.videoProgress]: this.getVideoProgress() ? parseInt(this.getVideoProgress) : 0,
            [AnalyticsKeys.videoUrl]: videoState.get(AnalyticsKeys.videoUrl),
            [AnalyticsKeys.watchTime]: this.getWatchTimeInt(),
        }

        return state.merge(newProps);
    }

    interactionForAction(action) {
        switch(action) {
            case ActionTypes.videoAdClicked: return AnalyticsValues.interactionValue
            case ActionTypes.videoAdCompleted: return AnalyticsValues.noninteractionValue
            case ActionTypes.videoAdStarted: return AnalyticsValues.noninteractionValue
            case ActionTypes.videoAdPlaying: return AnalyticsValues.noninteractionValue 
            case ActionTypes.videoAdSkipped: return AnalyticsValues.interactionValue            
            case ActionTypes.videoContentPlay: return AnalyticsValues.interactionValue
            case ActionTypes.videoContentCompleted: return AnalyticsValues.noninteractionValue 
            case ActionTypes.videoContentPlaying: return AnalyticsValues.noninteractionValue 
            case ActionTypes.videoContentStarted: return AnalyticsValues.noninteractionValue 
            case ActionTypes.videoContentPaused: return AnalyticsValues.interactionValue 
            case ActionTypes.videoContentResumed: return AnalyticsValues.interactionValue  
            case ActionTypes.videoLoaded: return AnalyticsValues.noninteractionValue 
            default: return AnalyticsValues.noninteractionValue
        }
    }

    pushOptionalProps(stateProperties) {
        let optionalFields = {};
        // Analytics keys with a value of type "string"
        const strKeys = [
         AnalyticsKeys.episodeUid, // Segment currently saying this is required
                                   // but schema is probably wrong bc live has no episodes
         AnalyticsKeys.seriesId,
         AnalyticsKeys.seriesTitle,
         AnalyticsKeys.seasonName,
         AnalyticsKeys.contentId,
         AnalyticsKeys.contentSource,
         AnalyticsKeys.externalContentSource,
         AnalyticsKeys.externalContentSourceId,
         AnalyticsKeys.discipline,
         AnalyticsKeys.athlete,
         AnalyticsKeys.contentGeography,
         AnalyticsKeys.contentReleaseDate,
         AnalyticsKeys.contentTitle,
         AnalyticsKeys.competitionEvent,
         AnalyticsKeys.slug,
         AnalyticsKeys.subtitleLanguage,
        ];
        // Analytics keys with a value of type "number"
        const numberKeys = [
          AnalyticsKeys.episodeNumber,
          AnalyticsKeys.seasonBucketId,
          AnalyticsKeys.programId,
        ];
        const hasKey = (key) => stateProperties.hasOwnProperty(key);
        strKeys.forEach((key) => {
            const keyExistsInState = hasKey(key);
            // add it to the optional fields if we have a value for the field
            if (keyExistsInState && (key !== undefined) && (stateProperties[key] !== '') && (stateProperties[key] !== null)) {
                optionalFields[key] = stateProperties[key];
            }
        })
        numberKeys.forEach((key) => {
            const keyExistsInState = hasKey(key);
            // add it to the optional fields if we have a value for the field
            if (keyExistsInState && (key !== undefined) && (stateProperties[key] !== -1) && (stateProperties[key] !== null)) {
                optionalFields[key] = stateProperties[key];
            }
        })
       return optionalFields;
    }

    convertStrPropToBool(strOrBool) {
        // Some "true"/"false" strings need to get converted 
        // to a true boolean to avoid Segment violations
        let bool;
        const nonEmptyArg = strOrBool ?? false // set to false if undefined or null 
 
        if (typeof nonEmptyArg === "string") {
            bool = JSON.parse(nonEmptyArg.toLowerCase()); 
        } 
        if (typeof nonEmptyArg === "boolean") {
            bool = nonEmptyArg
        }
        if (typeof nonEmptyArg !== "string" && typeof nonEmptyArg !== "boolean") {
            bool = false;
        }
        return bool;
    }

   
    getWatchTimeInt() {
        const videoState = VideoPlayerStore.getState();
        const currWatchTime = videoState.get(AnalyticsKeys.watchTime) ?? 0;
        const newWatchTime = parseInt(currWatchTime);

        return newWatchTime;
    }

     propsForEvent(event, stateProperties) {
        const requiredProps = {
            [AnalyticsKeys.event]: event,
            [AnalyticsKeys.nonIteraction]: this.interactionForAction(event),
            [AnalyticsKeys.implementationProvider]: AnalyticsValues.implementationProvider,
            [AnalyticsKeys.platform]: stateProperties[AnalyticsKeys.platform] ?? "ios",
            [AnalyticsKeys.authenticated]: this.convertStrPropToBool(stateProperties[AnalyticsKeys.authenticated]) ?? false,
            [AnalyticsKeys.language]: stateProperties[AnalyticsKeys.language] ?? "en",
            [AnalyticsKeys.userNation]: stateProperties[AnalyticsKeys.userNation] ?? "US",
            [AnalyticsKeys.pageType]: stateProperties[AnalyticsKeys.pageType] ?? "", // need this from iOS and Android
            [AnalyticsKeys.subdomain]: stateProperties[AnalyticsKeys.subdomain] ?? "", // need this from iOS and Android
        }
        const requiredVideoContentProps = {
            [AnalyticsKeys.videoId]: stateProperties[AnalyticsKeys.videoId] ?? "", // need this from Android
            [AnalyticsKeys.videoUrl]: stateProperties[AnalyticsKeys.videoUrl] ?? "",
            [AnalyticsKeys.videoType]: stateProperties[AnalyticsKeys.videoType] ?? "", 
            [AnalyticsKeys.videoProgress]:this.getVideoProgress() ?? 0 ,
            [AnalyticsKeys.watchTime]: this.getWatchTimeInt() ?? 0,
            [AnalyticsKeys.videoPlayer]: AnalyticsValues.videoPlayer,
            [AnalyticsKeys.videoChromecasted]: AnalyticsValues.videoChromecasted,   
        }
        const optionalVideoContentProps = this.pushOptionalProps(stateProperties);

        const videoContentProps = {
            ...requiredProps,
            ...requiredVideoContentProps,
            ...optionalVideoContentProps
        }
        
        switch (event) {
            case ActionTypes.videoAdClicked:
            case ActionTypes.videoAdCompleted:
            case ActionTypes.videoAdStarted:
            case ActionTypes.videoAdPlaying:
            case ActionTypes.videoAdSkipped:
                return requiredProps;
            case ActionTypes.videoContentPlay:
            case ActionTypes.videoContentCompleted:
            case ActionTypes.videoContentPlaying:
            case ActionTypes.videoContentStarted:
            case ActionTypes.videoContentPaused:
            case ActionTypes.videoContentResumed:
            case ActionTypes.videoLoaded:
                return videoContentProps;
            default:
                return videoContentProps;
                
        }
        // // TODO: If we are VOD event, do not send video progress or length values
        // return stateProperties
    }

    /**
     * Retreives video progress from the video player store properties.
     * @returns Returns the formatted video player progress (percent complete for this video) given the
     * media_time and video_length properties from the video player store.
     * If the progress cannot be computed, will return the existing value. if there is no existing value, will
     * return empty string.
     *
     * Note- The progress, like the other video time properties, is fixed to no decimal places.
     */
    getVideoProgress() {
        if (this.getState().get(AnalyticsKeys.videoType) === AnalyticsValues.vodVideoType) {
            const time = VideoPlayerStore.getState().get(AnalyticsKeys.watchTime);
            const duration = VideoPlayerStore.getState().get(AnalyticsKeys.videoLength);

            if (time !== null && duration !== null) {
                const timeInt = parseFloat(time);
                const durationInt = parseFloat(duration);
                if (timeInt !== null && durationInt !== null && durationInt > 0) {
                    return ((timeInt / durationInt) * 100).toFixed(0);
                }
            }
        }

        if (this.getState().get(AnalyticsKeys.videoProgress) !== undefined) {
            return this.getState().get(AnalyticsKeys.videoProgress);
        } else {
            return 0;
        }
    }

    defaultAnalyticsPayload() {
        return {
            [AnalyticsKeys.implementationProvider]: AnalyticsValues.implementationProvider,
            [AnalyticsKeys.videoPlayer]: AnalyticsValues.videoPlayer,
            [AnalyticsKeys.authenticated]: false,
        }
    }
}

export default new AnalyticsStore();
