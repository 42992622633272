const ActionTypes = {
    // Defined actions from OCS
    videoAdClicked: "Video Ad Clicked",
    videoAdCompleted: "Video Ad Completed",
    videoAdPlaying: "Video Ad Playing",
    videoAdSkipped: "Video Ad Skipped",
    videoAdStarted: "Video Ad Started",
    videoContentPlay: "Video Content Play",
    videoContentCompleted: "Video Content Completed",
    videoContentPlaying: "Video Content Playing",
    videoContentStarted: "Video Content Started",
    videoContentPaused: "Video Content Paused",
    videoContentResumed: "Video Content Resumed",
    videoLoaded: "Video Loaded",

    // Other actions from player
    videoBitrateChanged: "Video Bitrate Changed",
    videoProgressChanged: "Video Progress",
    videoPlayerPropertiesChanged: "Video Player Properties Changed",
    updateMediaItem: "Media Item Information Changed",
    playerLoading: "Player Loading New Item",

    // Other actions from context
    senderConnected: "Sender Connected",
    senderDisconnected: "Sender Disconnected",

    registerService: "Register Service",
}

/**
 * Actions that should trigger analytics calls, or be tracked.
 */
ActionTypes.trackingActions = [
    ActionTypes.videoAdClicked,
    ActionTypes.videoAdCompleted,
    ActionTypes.videoAdPlaying,
    ActionTypes.videoAdSkipped,
    ActionTypes.videoAdStarted,
    ActionTypes.videoContentPlay,
    ActionTypes.videoContentCompleted,
    ActionTypes.videoContentPlaying,
    ActionTypes.videoContentStarted,
    ActionTypes.videoContentPaused,
    ActionTypes.videoContentResumed,
    ActionTypes.videoLoaded,
]

export default ActionTypes;
